var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scalar-wrapper"
  }, _vm._l(_vm.sortedMetrics, function (row, row_idx) {
    return _c('div', {
      key: row_idx,
      class: row.classes
    }, [_c('h2', {
      staticClass: "h5 plot-caption"
    }, [_vm._v(" " + _vm._s(row.label) + " "), row.info ? _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on;
          return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
        }
      }], null, true)
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(row.info.replace(/\n/g, '<br>'))
      }
    })]) : _vm._e()], 1), _c('div', {
      staticClass: "scalar-plot-container d-flex",
      staticStyle: {
        "margin-top": "45px",
        "margin-bottom": "55px"
      }
    }, [_c('div', {
      staticClass: "scalar-plot-bar scalar-plot-bar-left text-right",
      style: _vm.buildBarStyles(row, 'left')
    }, [_c('div', {
      staticStyle: {
        "position": "relative",
        "margin-top": "20px"
      }
    }, [_vm._v(_vm._s(row.min_limit))])]), _c('div', {
      staticClass: "scalar-plot-bar scalar-plot-bar-middle",
      style: _vm.buildBarStyles(row, 'middle')
    }, [_c('div', {
      style: _vm.buildValueStyles(row.value, row.min_limit, row.max_limit)
    }, [_c('div', {
      staticStyle: {
        "width": "100px",
        "text-align": "center",
        "margin-left": "-50px"
      }
    }, [_vm._v(_vm._s(row.value))]), _c('div', {
      staticStyle: {
        "border": "1px solid #ffffff",
        "width": "4px",
        "height": "30px",
        "background": "#ffffff"
      }
    })])]), _c('div', {
      staticClass: "scalar-plot-bar scalar-plot-bar-right",
      style: _vm.buildBarStyles(row, 'right')
    }, [_c('div', {
      staticStyle: {
        "position": "relative",
        "margin-top": "20px"
      }
    }, [_vm._v(_vm._s(row.max_limit))])])])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }