var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticClass: "login-main",
    attrs: {
      "ma-0": "",
      "pa-3": "",
      "row": "",
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-flex', {
    staticClass: "d-flex flex-column align-stretch",
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "xl2": "",
      "pa-3": ""
    }
  }, [_c('h1', {
    staticClass: "white--text text-center"
  }, [_vm._v("E-mail verification code")]), _c('p', [_vm._v(" We've just sent you a 6-digit verification code by e-mail. Please copy paste the code below to access the website. If you don't see the e-mail in your mailbox, please check the spam folder. ")]), _c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Verification code",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Verification code",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.otp_token,
                  callback: function ($$v) {
                    _vm.otp_token = $$v;
                  },
                  expression: "otp_token"
                }
              })];
            }
          }], null, true)
        }), _c('v-btn', {
          staticClass: "white--text mx-0 align-self-center",
          attrs: {
            "type": "submit",
            "disabled": _vm.submitted && invalid || _vm.loading
          },
          on: {
            "click": function ($event) {
              return _vm.onLogin();
            }
          }
        }, [_vm._v("Verify")])];
      }
    }])
  }), _c('router-link', {
    staticClass: "text-center mt-6",
    attrs: {
      "to": {
        name: 'Login'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleGoBack.apply(null, arguments);
      }
    }
  }, [_vm._v(" Back to Login ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }