var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "speed-control d-flex justify-center"
  }, _vm._l(_vm.speeds, function (s, index) {
    return _c('div', {
      key: index,
      staticClass: "speed",
      class: {
        selected: s.value === _vm.value
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('input', s.value);
        }
      }
    }, [_vm._v(" " + _vm._s(s.name) + " ")]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }