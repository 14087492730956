var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "500",
      "persistent": ""
    },
    on: {
      "click:outside": _vm.closeDialog
    },
    model: {
      value: _vm.edit_dialog,
      callback: function ($$v) {
        _vm.edit_dialog = $$v;
      },
      expression: "edit_dialog"
    }
  }, [_c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "form"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('v-form', [_c('v-card', [_vm.edited_subject.id ? _c('v-card-title', {
          staticClass: "headline"
        }, [_vm._v("Edit subject \"" + _vm._s(_vm.edited_subject.name) + "\"")]) : _c('v-card-title', {
          staticClass: "headline"
        }, [_vm._v("Create new subject")]), _c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-text-field', {
          attrs: {
            "label": "Name",
            "required": "",
            "error": _vm.formErrors.name != null,
            "error-messages": _vm.formErrors.name
          },
          model: {
            value: _vm.edited_subject.name,
            callback: function ($$v) {
              _vm.$set(_vm.edited_subject, "name", $$v);
            },
            expression: "edited_subject.name"
          }
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required|weightRule",
            "name": "Weight",
            "immediate": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Weight (kg)",
                  "type": "number",
                  "hide-spin-buttons": "",
                  "required": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.edited_subject.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.edited_subject, "weight", $$v);
                  },
                  expression: "edited_subject.weight"
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required|heightRule",
            "name": "Height",
            "immediate": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Height (m)",
                  "type": "number",
                  "hide-spin-buttons": "",
                  "required": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.edited_subject.height,
                  callback: function ($$v) {
                    _vm.$set(_vm.edited_subject, "height", $$v);
                  },
                  expression: "edited_subject.height"
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required|birthYearRule",
            "name": "Birth Year",
            "immediate": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var errors = _ref4.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Birth year (yyyy)",
                  "type": "number",
                  "hide-spin-buttons": "",
                  "required": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.edited_subject.birth_year,
                  callback: function ($$v) {
                    _vm.$set(_vm.edited_subject, "birth_year", $$v);
                  },
                  expression: "edited_subject.birth_year"
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required|subjectTagsRule",
            "name": "Subject Tags",
            "immediate": false
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var errors = _ref5.errors;
              return [_c('v-autocomplete', {
                ref: "subjectTagsSelect",
                attrs: {
                  "clearable": "",
                  "multiple": "",
                  "item-title": "text",
                  "item-value": "value",
                  "label": "Subject Tags",
                  "items": _vm.tagsOptions,
                  "error": errors.length > 0,
                  "error-messages": errors[0],
                  "search-input": _vm.tag_search_input
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.tag_search_input = $event;
                  },
                  "update:search-input": function ($event) {
                    _vm.tag_search_input = $event;
                  },
                  "change": function ($event) {
                    _vm.tag_search_input = '';
                  }
                },
                model: {
                  value: _vm.edited_subject.subject_tags,
                  callback: function ($$v) {
                    _vm.$set(_vm.edited_subject, "subject_tags", $$v);
                  },
                  expression: "edited_subject.subject_tags"
                }
              })];
            }
          }], null, true)
        }), _c('v-select', {
          ref: "sexAtBirthSelect",
          attrs: {
            "clearable": "",
            "item-title": "text",
            "item-value": "value",
            "label": "Sex assigned at birth (Optional)",
            "items": _vm.sexesOptions
          },
          model: {
            value: _vm.edited_subject.sex_at_birth,
            callback: function ($$v) {
              _vm.$set(_vm.edited_subject, "sex_at_birth", $$v);
            },
            expression: "edited_subject.sex_at_birth"
          }
        }), _c('v-select', {
          ref: "genderSelect",
          attrs: {
            "clearable": "",
            "item-title": "text",
            "item-value": "value",
            "label": "Gender (Optional)",
            "items": _vm.gendersOptions
          },
          model: {
            value: _vm.edited_subject.gender,
            callback: function ($$v) {
              _vm.$set(_vm.edited_subject, "gender", $$v);
            },
            expression: "edited_subject.gender"
          }
        }), _c('v-textarea', {
          attrs: {
            "label": "Characteristics (Optional)",
            "rows": "3"
          },
          model: {
            value: _vm.edited_subject.characteristics,
            callback: function ($$v) {
              _vm.$set(_vm.edited_subject, "characteristics", $$v);
            },
            expression: "edited_subject.characteristics"
          }
        }), _c('div', {
          staticClass: "pt-0"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": {
              required: {
                allowFalse: false
              }
            },
            "name": "Informed consent selection"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref6) {
              var errors = _ref6.errors;
              return [_c('v-checkbox', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function () {
                    return [_c('div', [_vm._v("I, the research Participant, have provided informed consent to the research Investigator conducting this study. I have read and I agree to the "), _c('v-tooltip', {
                      attrs: {
                        "location": "bottom"
                      },
                      scopedSlots: _vm._u([{
                        key: "activator",
                        fn: function (_ref7) {
                          var props = _ref7.props;
                          return [_c('a', _vm._b({
                            attrs: {
                              "href": "https://www.opencap.ai/terms-conditions",
                              "target": "_blank"
                            },
                            on: {
                              "click": function ($event) {
                                $event.stopPropagation();
                              }
                            }
                          }, 'a', props, false), [_vm._v("Terms and Conditions")])];
                        }
                      }], null, true)
                    }, [_vm._v(" Opens in new window ")]), _vm._v(" and "), _c('v-tooltip', {
                      attrs: {
                        "location": "bottom"
                      },
                      scopedSlots: _vm._u([{
                        key: "activator",
                        fn: function (_ref8) {
                          var props = _ref8.props;
                          return [_c('a', _vm._b({
                            attrs: {
                              "href": "https://docs.google.com/document/d/1DBw9LVAuUwgz713037VQjsaD8sj2-AN_hzga_7kXtXI",
                              "target": "_blank"
                            },
                            on: {
                              "click": function ($event) {
                                $event.stopPropagation();
                              }
                            }
                          }, 'a', props, false), [_vm._v("Privacy Policy")])];
                        }
                      }], null, true)
                    }), _vm._v(" of the OpenCap tool. ")], 1)];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.edited_subject.terms,
                  callback: function ($$v) {
                    _vm.$set(_vm.edited_subject, "terms", $$v);
                  },
                  expression: "edited_subject.terms"
                }
              }, [_vm._v(" Opens in new window ")])];
            }
          }], null, true)
        })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.cancelSubjectForm();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('v-btn', {
          attrs: {
            "color": "green darken-1",
            "text": "",
            "disabled": invalid
          },
          on: {
            "click": function ($event) {
              return _vm.submitSubjectForm();
            }
          }
        }, [_vm._v(" Save ")])], 1)], 1)], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }