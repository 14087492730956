var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "icon-button",
    class: {
      inline: _vm.inline
    },
    style: _vm.getStyle,
    attrs: {
      "role": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.roles[_vm.role].icon))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }