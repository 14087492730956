var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticClass: "login-main",
    attrs: {
      "ma-0": "",
      "pa-3": "",
      "row": "",
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-flex', {
    staticClass: "wrapper-box d-flex flex-column align-stretch scroll-y",
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "xl2": "",
      "pa-3": ""
    }
  }, [_c('h1', {
    staticClass: "white--text text-center"
  }, [_vm._v("Retrieve username and/or change password")]), _c('span', {
    staticClass: "text-center mt-2"
  }, [_vm._v("If your email exists in our database, we will send you an email with your username and a link to change your password.")]), _c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('ValidationProvider', {
          attrs: {
            "rules": "required|email",
            "name": "Email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Email",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v;
                  },
                  expression: "email"
                }
              })];
            }
          }], null, true)
        }), _c('v-btn', {
          staticClass: "white--text mx-0 align-self-center",
          attrs: {
            "type": "submit",
            "disabled": _vm.submitted && invalid || _vm.loading
          },
          on: {
            "click": function ($event) {
              return _vm.onReset();
            }
          }
        }, [_vm._v("Send Email")])];
      }
    }])
  }), _c('router-link', {
    staticClass: "mt-4 text-center",
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_c('span', {
    on: {
      "click": _vm.clearToasted
    }
  }, [_vm._v("Back to Login")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }