var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainLayout', {
    attrs: {
      "column": "",
      "leftButton": "Back",
      "rightButton": "Calibrate",
      "step": 2,
      "rightDisabled": _vm.busy
    },
    on: {
      "left": function ($event) {
        return _vm.$router.push(`/${_vm.session.id}/connect-devices`);
      },
      "right": _vm.onNext
    }
  }, [_c('v-card', {
    staticClass: "step-2-1"
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center"
  }, [_c('p', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._v(_vm._s(_vm.n_videos_uploaded) + " of " + _vm._s(_vm.n_cameras_connected) + " videos uploaded.")])])], 1), _c('v-card', {
    staticClass: "step-2-2 mt-4 flex-grow-1"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm._v(" Place a checkerboard in the scene ")]), _c('v-card-text', {
    staticClass: "d-flex align-center"
  }, [_c('ul', {
    staticClass: "flex-grow-1"
  }, [_c('li', [_vm._v("It should be visible by all cameras (nothing in the way of cameras' view when hitting Calibrate)")]), _c('li', [_vm._v("It can be either perpendicular to the floor (default) or lying on the floor (beta feature; select Lying placement below)")]), _c('li', [_vm._v("If perpendicular to the floor, then: "), _c('ul', [_c('li', [_vm._v("Place it horizontally (longer side on the floor)")])])])]), _c('div', {
    staticClass: "image-container pa-3"
  }, [_c('img', {
    attrs: {
      "src": "/images/checkerboard-placement.png"
    }
  })])])], 1), _c('v-card', {
    staticClass: "step-2-2 mt-4 flex-grow-1"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm._v(" Provide the checkerboard details ")]), _c('v-card-text', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-flex flex-grow-1 align-center inputs"
  }, [_c('v-text-field', {
    staticClass: "mr-3",
    attrs: {
      "label": "Rows"
    },
    model: {
      value: _vm.rows,
      callback: function ($$v) {
        _vm.rows = $$v;
      },
      expression: "rows"
    }
  }), _c('v-text-field', {
    staticClass: "mr-3",
    attrs: {
      "label": "Columns"
    },
    model: {
      value: _vm.cols,
      callback: function ($$v) {
        _vm.cols = $$v;
      },
      expression: "cols"
    }
  }), _c('v-text-field', {
    staticClass: "mr-3",
    attrs: {
      "label": "Square size (mm)"
    },
    model: {
      value: _vm.squareSize,
      callback: function ($$v) {
        _vm.squareSize = $$v;
      },
      expression: "squareSize"
    }
  }), _c('v-select', {
    staticClass: "mr-0",
    attrs: {
      "items": ['Perpendicular', 'Lying'],
      "label": "Placement on the floor"
    },
    model: {
      value: _vm.placement,
      callback: function ($$v) {
        _vm.placement = $$v;
      },
      expression: "placement"
    }
  }), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({
          staticClass: "ml-0"
        }, on), [_vm._v("mdi-help-circle-outline")])];
      }
    }])
  }, [_c('div', [_vm._v(" The origin of the world frame is the top-left black-to-black corner of the board (red dot with a blue outline in the picture on the right). "), _c('br'), _c('br'), _vm._v(" When positioned perpendicular to the floor, transformations are applied so that in the processed data: "), _c('ul', [_c('li', [_vm._v("The forward axis of the world frame is perpendicular to the board (coming out).")]), _c('li', [_vm._v("The vertical axis of the world frame is parallel to the board (going up).")])]), _c('br'), _vm._v(" When positioned lying on the floor, transformations are applied so that in the processed data: "), _c('ul', [_c('li', [_vm._v("The forward axis of the world frame is parallel to the board (along the shorter side).")]), _c('li', [_vm._v("The vertical axis of the world frame is perpendicular to the board (going up).")])]), _c('br'), _vm._v(" To align movement with the forward axis of the world frame when the board is lying on the floor, place the board such that its forward axis is parallel to the direction of movement. For example, for walking, place the board with the longer side perpendicular to the walking direction. Note that this alignment is optional, as the system can operate with the board in any orientation. ")])])], 1), _c('div', {
    staticClass: "image-container pa-3"
  }, [_c('img', {
    attrs: {
      "src": "/images/checkerboard_45.png"
    }
  })])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }