var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticClass: "login-main",
    attrs: {
      "ma-0": "",
      "pa-3": "",
      "row": "",
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-flex', {
    staticClass: "d-flex flex-column align-stretch",
    attrs: {
      "xs12": "",
      "sm10": "",
      "md8": "",
      "lg6": "",
      "xl4": "",
      "pa-3": ""
    }
  }, [_c('v-card', [_c('v-card-text', [!_vm.secondaryMessage ? _c('h3', {
    staticClass: "white--text text-center"
  }, [_vm._v(" OpenCap is free for academic research and educational use ("), _c('a', {
    staticStyle: {
      "color": "#7799cf",
      "text-decoration": "underline"
    },
    attrs: {
      "href": "https://www.opencap.ai/terms-conditions"
    }
  }, [_vm._v("Terms & Conditions")]), _vm._v("). Please identify how you use / intend to use OpenCap:")]) : _c('h3', {
    staticClass: "white--text text-center"
  }, [_vm._v(" Your previously indicated that you intended to use OpenCap for an application not permitted by the OpenCap license. Has you intended use changed? OpenCap is free for academic research and educational use ("), _c('a', {
    staticStyle: {
      "color": "#7799cf",
      "text-decoration": "underline"
    },
    attrs: {
      "href": "https://www.opencap.ai/terms-conditions"
    }
  }, [_vm._v("Terms & Conditions")]), _vm._v("). Please identify how you use / intend to use OpenCap:")]), _c('v-radio-group', {
    on: {
      "change": function ($event) {
        return _vm.submit(false);
      }
    },
    model: {
      value: _vm.institutionalUse,
      callback: function ($$v) {
        _vm.institutionalUse = $$v;
      },
      expression: "institutionalUse"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Patient care",
      "value": "patient_care"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Sports performance assessment",
      "value": "sports_performance_assessment"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Use in a company or in collaboration with a company",
      "value": "use_in_company"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Research at an academic institution",
      "value": "research_at_academic"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Teaching at an academic institution",
      "value": "teaching_at_academic"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "Other non-commercial activity",
      "value": "other_non_commercial"
    }
  })], 1), _vm.notPermitted ? _c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v(" This use is not a permitted use of OpenCap, please contact Model Health ("), _c('a', {
    attrs: {
      "href": "mailto:info@modelhealth.io"
    }
  }, [_vm._v("info@modelhealth.io")]), _vm._v(") to purchase a license. ")]) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.institutionalUse == '' || _vm.notPermitted
    },
    on: {
      "click": function ($event) {
        return _vm.submit(true);
      }
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }