var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container d-flex justify-content-center text-center",
    attrs: {
      "id": "container"
    }
  }, [_c('div', {
    staticClass: "w-50 p-3 mx-auto"
  }, [_c('h1', {
    staticClass: "white--text text-center col-md-12"
  }, [_vm._v("Register")]), _c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Login"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Username",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v;
                  },
                  expression: "username"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "First name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "First name",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.first_name,
                  callback: function ($$v) {
                    _vm.first_name = $$v;
                  },
                  expression: "first_name"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Last name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var errors = _ref4.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Last name",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.last_name,
                  callback: function ($$v) {
                    _vm.last_name = $$v;
                  },
                  expression: "last_name"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6 d-flex align-items-center"
        }, [_c('div', {
          staticClass: "form-outline datepicker w-100"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|email",
            "name": "Email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var errors = _ref5.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Email (will be used for two-factor authentication)",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v;
                  },
                  expression: "email"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('vue-country-dropdown', {
          ref: "vcd",
          attrs: {
            "preferredCountries": ['US'],
            "defaultCountry": 'US',
            "immediateCallSelectEvent": true,
            "enabledFlags": true,
            "enabledCountryCode": false,
            "showNameInput": true
          },
          on: {
            "onSelect": _vm.onSelectCountry
          }
        })], 1)]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6 pb-2"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Institution"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref6) {
              var errors = _ref6.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Institution",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.institution,
                  callback: function ($$v) {
                    _vm.institution = $$v;
                  },
                  expression: "institution"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col-md-6 pb-2"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Profession"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref7) {
              var errors = _ref7.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Profession",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.profession,
                  callback: function ($$v) {
                    _vm.profession = $$v;
                  },
                  expression: "profession"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Reason"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref8) {
              var errors = _ref8.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Reason for use",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.reason,
                  callback: function ($$v) {
                    _vm.reason = $$v;
                  },
                  expression: "reason"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Website"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref9) {
              var errors = _ref9.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Website",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.website,
                  callback: function ($$v) {
                    _vm.website = $$v;
                  },
                  expression: "website"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6 pb-2"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|min:20",
            "name": "Password",
            "vid": "password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref10) {
              var errors = _ref10.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Password (20+ characters)",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0],
                  "append-icon": _vm.show_password ? 'mdi-eye' : 'mdi-eye-off',
                  "type": _vm.show_password ? 'text' : 'password'
                },
                on: {
                  "click:append": function ($event) {
                    _vm.show_password = !_vm.show_password;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "append",
                  fn: function () {
                    return [_c('v-icon', {
                      staticClass: "show-pass-icon",
                      attrs: {
                        "tabindex": -1
                      },
                      on: {
                        "click": function ($event) {
                          _vm.show_password = !_vm.show_password;
                        }
                      }
                    }, [_vm._v(" " + _vm._s(_vm.show_password ? 'mdi-eye' : 'mdi-eye-off') + " ")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v;
                  },
                  expression: "password"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col-md-6 pb-2"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|confirmed:password",
            "name": "Confirm password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref11) {
              var errors = _ref11.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Confirm password",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0],
                  "append-icon": _vm.show_confirm_password ? 'mdi-eye' : 'mdi-eye-off',
                  "type": _vm.show_confirm_password ? 'text' : 'password'
                },
                on: {
                  "click:append": function ($event) {
                    _vm.show_confirm_password = !_vm.show_confirm_password;
                  }
                },
                scopedSlots: _vm._u([{
                  key: "append",
                  fn: function () {
                    return [_c('v-icon', {
                      staticClass: "show-pass-icon",
                      attrs: {
                        "tabindex": -1
                      },
                      on: {
                        "click": function ($event) {
                          _vm.show_confirm_password = !_vm.show_confirm_password;
                        }
                      }
                    }, [_vm._v(" " + _vm._s(_vm.show_confirm_password ? 'mdi-eye' : 'mdi-eye-off') + " ")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.confirmPassword,
                  callback: function ($$v) {
                    _vm.confirmPassword = $$v;
                  },
                  expression: "confirmPassword"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('v-checkbox', {
          attrs: {
            "label": "Sign up to receive our newsletter"
          },
          model: {
            value: _vm.newsletter,
            callback: function ($$v) {
              _vm.newsletter = $$v;
            },
            expression: "newsletter"
          }
        })], 1), _c('div', {
          staticClass: "col-12 pb-0"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": {
              required: {
                allowFalse: false
              }
            },
            "name": "Terms and Conditions agreement selection"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref12) {
              var errors = _ref12.errors;
              return [_c('v-checkbox', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function () {
                    return [_c('div', [_vm._v("I confirm that I have read and agree to the "), _c('v-tooltip', {
                      attrs: {
                        "location": "bottom"
                      },
                      scopedSlots: _vm._u([{
                        key: "activator",
                        fn: function (_ref13) {
                          var props = _ref13.props;
                          return [_c('a', _vm._b({
                            attrs: {
                              "href": "https://www.opencap.ai/terms-conditions",
                              "target": "_blank"
                            },
                            on: {
                              "click": function ($event) {
                                $event.stopPropagation();
                              }
                            }
                          }, 'a', props, false), [_vm._v("Terms and Conditions")])];
                        }
                      }], null, true)
                    }, [_vm._v(" Opens in new window ")]), _vm._v(" of OpenCap. ")], 1)];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.terms,
                  callback: function ($$v) {
                    _vm.terms = $$v;
                  },
                  expression: "terms"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-12 pt-0 pb-0"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": {
              required: {
                allowFalse: false
              }
            },
            "name": "Privacy Policy agreement selection"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref14) {
              var errors = _ref14.errors;
              return [_c('v-checkbox', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function () {
                    return [_c('div', [_vm._v("I confirm that I have read and agree to the the "), _c('v-tooltip', {
                      attrs: {
                        "location": "bottom"
                      },
                      scopedSlots: _vm._u([{
                        key: "activator",
                        fn: function (_ref15) {
                          var props = _ref15.props;
                          return [_c('a', _vm._b({
                            attrs: {
                              "href": "https://docs.google.com/document/d/1DBw9LVAuUwgz713037VQjsaD8sj2-AN_hzga_7kXtXI",
                              "target": "_blank"
                            },
                            on: {
                              "click": function ($event) {
                                $event.stopPropagation();
                              }
                            }
                          }, 'a', props, false), [_vm._v("Privacy Policy")])];
                        }
                      }], null, true)
                    }, [_vm._v(" Opens in new window ")]), _vm._v(" of OpenCap. ")], 1)];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.privacy,
                  callback: function ($$v) {
                    _vm.privacy = $$v;
                  },
                  expression: "privacy"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-12 pt-0"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": {
              required: {
                allowFalse: false
              }
            },
            "name": "Non-profit use agreement selection"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref16) {
              var errors = _ref16.errors;
              return [_c('v-checkbox', {
                staticClass: "mt-0 mb-0",
                attrs: {
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function () {
                    return [_c('div', [_vm._v("I confirm that I am using OpenCap for academic or non-profit organization non-commercial research or educational use only and that my use of OpenCap is in compliance with the relevant privacy, security, and human subjects research regulations in my location.")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.nonprofit,
                  callback: function ($$v) {
                    _vm.nonprofit = $$v;
                  },
                  expression: "nonprofit"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "pt-2"
        }, [_c('v-btn', {
          staticClass: "white--text mx-0 align-self-center mb-6",
          attrs: {
            "type": "submit",
            "disabled": _vm.submitted && invalid || _vm.loading
          },
          on: {
            "click": function ($event) {
              return _vm.onRegister();
            }
          }
        }, [_vm._v("Register")])], 1)];
      }
    }])
  }), _c('router-link', {
    staticClass: "text-center mt-6",
    attrs: {
      "to": {
        name: 'Login'
      }
    }
  }, [_vm._v("Back to Login")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }