var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "select-session d-flex flex-column"
  }, [_c('div', {
    staticClass: "pa-2 d-flex"
  }, [_c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'ConnectDevices'
        });
      }
    }
  }, [_vm._v(" New session ")]), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ml-2",
          attrs: {
            "dark": ""
          }
        }, 'v-btn', attrs, false), on), [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v("Dashboards")]), _c('v-icon', [_vm._v("mdi-menu")])], 1)];
      }
    }])
  }, [_c('v-list', [_c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'Dashboard',
          params: {
            id: ''
          }
        });
      }
    }
  }, [_vm._v(" Kinematics ")]), _vm._l(_vm.analysis_dashboards, function (dashboard) {
    return _c('v-list-item', {
      key: dashboard.id,
      on: {
        "click": function ($event) {
          return _vm.$router.push({
            name: 'AnalysisDashboard',
            params: {
              id: dashboard.id
            }
          });
        }
      }
    }, [_vm._v(" " + _vm._s(dashboard.title))]);
  })], 2)], 1), _c('v-btn', {
    staticClass: "ml-2",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'Subjects'
        });
      }
    }
  }, [_vm._v(" Subjects ")]), _c('v-btn', {
    staticClass: "ml-2",
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'RecycleBin'
        });
      }
    }
  }, [_vm._v(" Recycle Bin ")]), _c('v-checkbox', {
    staticClass: "ml-2 mt-0",
    attrs: {
      "label": "Show removed sessions"
    },
    model: {
      value: _vm.show_trashed,
      callback: function ($$v) {
        _vm.show_trashed = $$v;
      },
      expression: "show_trashed"
    }
  })], 1), _c('v-data-table', {
    staticClass: "sessions-table mx-2 mb-4 flex-grow-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.valid_sessions,
      "options": _vm.options,
      "item-class": _vm.itemClasses,
      "loading": _vm.loading,
      "server-items-length": _vm.session_total,
      "footer-props": {
        showFirstLastPage: false,
        disableItemsPerPage: true,
        itemsPerPageOptions: [40]
      },
      "fixed-header": "",
      "single-select": ""
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event;
      },
      "item-selected": _vm.onSelect,
      "click:row": _vm.onRowClick
    },
    scopedSlots: _vm._u([{
      key: "item.created_at",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("date")(item.created_at)))])];
      }
    }, {
      key: "item.id",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "float-right"
        }, [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "dark": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
            }
          }], null, true),
          model: {
            value: item.isMenuOpen,
            callback: function ($$v) {
              _vm.$set(item, "isMenuOpen", $$v);
            },
            expression: "item.isMenuOpen"
          }
        }, [_c('v-list', [_c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push({
                name: 'Session',
                params: {
                  id: item.id
                }
              });
            }
          }
        }, [_c('v-list-item-title', [_vm._v("Load")])], 1), _c('v-list-item', {
          attrs: {
            "link": ""
          },
          on: {
            "click": function ($event) {
              return _vm.$router.push({
                name: 'Dashboard',
                params: {
                  id: item.id
                }
              });
            }
          }
        }, [_c('v-list-item-title', [_vm._v("Dashboard kinematics")])], 1), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSessionHideMenu,
            expression: "clickOutsideDialogSessionHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref5) {
              var on = _ref5.on;
              return [_c('v-list-item', _vm._g({
                attrs: {
                  "link": ""
                }
              }, Object.assign({}, on, {
                click: function (e) {
                  on.click(e);
                  item.isMenuOpen = false;
                  _vm.rename_dialog = true;
                }
              })), [_c('v-list-item-title', [_vm._v("Rename")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.rename_dialog,
            callback: function ($$v) {
              _vm.rename_dialog = $$v;
            },
            expression: "rename_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "orange"
          }
        }, [_vm._v("mdi-rename-box")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', {
          staticClass: "mb-1"
        }, [_vm._v(" Insert a new name for session " + _vm._s(item.sessionName) + ": ")]), _c('small', {
          staticClass: "mt-0"
        }, [_vm._v(" Only alphanumeric characters and underscores are allowed. ")]), _c('ValidationObserver', {
          ref: "observer",
          staticClass: "d-flex flex-column",
          attrs: {
            "tag": "div"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref6) {
              var invalid = _ref6.invalid;
              return [_c('ValidationProvider', {
                attrs: {
                  "rules": "required|alpha_dash_custom",
                  "name": "Session name"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function (_ref7) {
                    var errors = _ref7.errors;
                    return [_c('v-text-field', {
                      staticClass: "flex-grow-0",
                      attrs: {
                        "label": "Session new name",
                        "dark": "",
                        "error": errors.length > 0,
                        "error-messages": errors[0]
                      },
                      model: {
                        value: _vm.sessionNewName,
                        callback: function ($$v) {
                          _vm.sessionNewName = $$v;
                        },
                        expression: "sessionNewName"
                      }
                    })];
                  }
                }], null, true)
              }), _c('v-spacer'), _c('v-btn', {
                staticClass: "text-right",
                attrs: {
                  "disabled": invalid
                },
                on: {
                  "click": function ($event) {
                    item.isMenuOpen = false;
                    _vm.rename_dialog = false;

                    _vm.renameSession(item, _vm.sessionNewName);
                  }
                }
              }, [_vm._v(" Rename Session ")])];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSessionHideMenu,
            expression: "clickOutsideDialogSessionHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref8) {
              var on = _ref8.on;
              return [_c('v-list-item', _vm._g({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: !item.trashed,
                  expression: "!item.trashed"
                }],
                attrs: {
                  "link": ""
                }
              }, Object.assign({}, on, {
                click: function (e) {
                  on.click(e);
                  item.isMenuOpen = false;
                  _vm.remove_dialog = true;
                }
              })), [_c('v-list-item-title', [_vm._v("Trash")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.remove_dialog,
            callback: function ($$v) {
              _vm.remove_dialog = $$v;
            },
            expression: "remove_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "red"
          }
        }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to trash session "), _c('code', [_vm._v(_vm._s(item.id))]), _vm._v("? You will be able to restore it for 30 days. After that, this session will be permanently removed. ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_dialog = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "red darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.remove_dialog = false;

              _vm.trashSession(item.id);
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
          directives: [{
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.clickOutsideDialogSessionHideMenu,
            expression: "clickOutsideDialogSessionHideMenu"
          }],
          attrs: {
            "max-width": "500"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref9) {
              var on = _ref9.on;
              return [_c('v-list-item', _vm._g({
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: item.trashed,
                  expression: "item.trashed"
                }],
                attrs: {
                  "link": ""
                }
              }, Object.assign({}, on, {
                click: function (e) {
                  on.click(e);
                  item.isMenuOpen = false;
                  _vm.restore_dialog = true;
                }
              })), [_c('v-list-item-title', _vm._g({}, on), [_vm._v("Restore")])], 1)];
            }
          }], null, true),
          model: {
            value: _vm.restore_dialog,
            callback: function ($$v) {
              _vm.restore_dialog = $$v;
            },
            expression: "restore_dialog"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pt-4"
        }, [_c('v-row', {
          staticClass: "m-0"
        }, [_c('v-col', {
          attrs: {
            "cols": "2"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "green"
          }
        }, [_vm._v("mdi-undo-variant")])], 1), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('p', [_vm._v(" Do you want to restore session "), _c('code', [_vm._v(_vm._s(item.id))]), _vm._v("? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "blue darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.restore_dialog = false;
            }
          }
        }, [_vm._v(" No ")]), _c('v-btn', {
          attrs: {
            "color": "green darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              item.isMenuOpen = false;
              _vm.restore_dialog = false;

              _vm.restoreSession(item.id);
            }
          }
        }, [_vm._v(" Yes ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "mt-2"
        }, [_vm._v(_vm._s(item.id))])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }