var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-dropdown mr-4",
    on: {
      "click": _vm.toggleDropdown
    }
  }, [_c('img', {
    staticClass: "profile-image",
    attrs: {
      "src": _vm.profile_picture_url,
      "alt": "Profile"
    }
  }), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isDropdownOpen ? _c('div', {
    staticClass: "dropdown-content"
  }, [_c('ul', [_c('router-link', {
    staticClass: "dropdown-element",
    attrs: {
      "tag": "li",
      "to": {
        name: 'SelectSession'
      }
    }
  }, [_c('li', [_vm._v("Signed in as: " + _vm._s(_vm.username))])])], 1), _c('hr'), _c('ul', [_c('router-link', {
    staticClass: "dropdown-element",
    attrs: {
      "tag": "li",
      "to": {
        name: 'ProfilePage',
        params: {
          username: this.username
        }
      }
    }
  }, [_c('i', {
    staticClass: "mdi mdi-account inline-icon"
  }), _vm._v(" Your Profile ")]), _c('router-link', {
    staticClass: "dropdown-element",
    attrs: {
      "tag": "li",
      "to": {
        name: 'SelectSession'
      }
    }
  }, [_c('i', {
    staticClass: "mdi mdi-magnify inline-icon"
  }), _vm._v(" Your Sessions ")])], 1), _c('hr'), _c('ul', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://simtk.org/plugins/phpBB/indexPhpbb.php?group_id=2385&pluginname=phpBB"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-forum inline-icon"
  }), _vm._v(" Forum ")])]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://github.com/stanfordnmbl/opencap-core"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-source-repository inline-icon"
  }), _vm._v(" Find on GitHub ")])]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://github.com/stanfordnmbl/opencap-processing"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-file-chart inline-icon"
  }), _vm._v(" Data Analysis and Processing ")])]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.opencap.ai/best-practices"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-help inline-icon"
  }), _vm._v(" Best Practices ")])]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://mobilize.stanford.edu/wp-content/uploads/2022/12/OpenCap-QA-Final.pdf"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-frequently-asked-questions inline-icon"
  }), _vm._v(" FAQ ")])]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.opencap.ai/terms-conditions"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-file-document-edit inline-icon"
  }), _vm._v(" Terms and Conditions ")])]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://docs.google.com/document/d/1DBw9LVAuUwgz713037VQjsaD8sj2-AN_hzga_7kXtXI/edit"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-eye inline-icon"
  }), _vm._v(" Data Privacy and Security ")])]), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://github.com/stanfordnmbl/opencap-core/issues"
    }
  }, [_c('li', [_c('i', {
    staticClass: "mdi mdi-bug inline-icon"
  }), _vm._v(" Bug Report ")])])]), _c('hr'), _c('ul', [_c('li', {
    on: {
      "click": _vm.logoutAction
    }
  }, [_c('i', {
    staticClass: "mdi mdi-logout inline-icon"
  }), _vm._v(" Logout ")])])]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }