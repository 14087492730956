var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    staticClass: "login-main",
    attrs: {
      "ma-0": "",
      "pa-3": "",
      "row": "",
      "justify-center": "",
      "align-center": "",
      "fill-height": ""
    }
  }, [_c('v-flex', {
    staticClass: "wrapper-box d-flex flex-column align-stretch scroll-y",
    attrs: {
      "xs12": "",
      "sm6": "",
      "md4": "",
      "lg3": "",
      "xl2": "",
      "pa-3": ""
    }
  }, [_c('h1', {
    staticClass: "white--text text-center"
  }, [_vm._v("Login")]), _c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Username",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Username",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v;
                  },
                  expression: "username"
                }
              })];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Password",
            "slim": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('v-text-field', {
                attrs: {
                  "label": "Password",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0],
                  "append-icon": _vm.show_password ? 'mdi-eye' : 'mdi-eye-off',
                  "type": _vm.show_password ? 'text' : 'password'
                },
                on: {
                  "click:append": function ($event) {
                    _vm.show_password = !_vm.show_password;
                  }
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v;
                  },
                  expression: "password"
                }
              })];
            }
          }], null, true)
        }), _vm.show_remember_checkbox ? _c('v-checkbox', {
          attrs: {
            "label": "Remember this device for 90 days",
            "dark": ""
          },
          model: {
            value: _vm.remember_device,
            callback: function ($$v) {
              _vm.remember_device = $$v;
            },
            expression: "remember_device"
          }
        }) : _vm._e(), _c('v-btn', {
          staticClass: "white--text mx-0 align-self-center",
          attrs: {
            "type": "submit",
            "disabled": _vm.submitted && invalid || _vm.loading
          },
          on: {
            "click": function ($event) {
              return _vm.onLogin();
            }
          }
        }, [_vm._v("Login")])];
      }
    }])
  }), _c('router-link', {
    staticClass: "mt-4 text-center",
    attrs: {
      "to": {
        name: 'ResetPassword'
      }
    }
  }, [_vm._v("Forgot your username or password?")]), _c('span', {
    staticClass: "text-center mt-2"
  }, [_vm._v(" -- ")]), _c('router-link', {
    staticClass: "mt-4 text-center",
    attrs: {
      "to": {
        name: 'Register'
      }
    }
  }, [_vm._v("Don't have an account yet? Sign Up")]), _c('span', {
    staticClass: "text-center mt-2"
  }, [_vm._v(" -- ")]), _c('span', {
    staticClass: "text-center mt-2"
  }, [_c('a', {
    attrs: {
      "href": "https://www.opencap.ai/get-started"
    }
  }, [_vm._v("Gather the materials described on our Get Started page before collecting data.")])]), _c('span', {
    staticClass: "text-center mt-2"
  }, [_vm._v(" -- ")]), _c('span', {
    staticClass: "text-center mt-2"
  }, [_c('a', {
    attrs: {
      "href": "https://www.opencap.ai/best-practices"
    }
  }, [_vm._v("Watch the quick tutorial videos on our Best Practices page before collecting data.")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }