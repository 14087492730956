var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MainLayout', {
    attrs: {
      "leftButton": "Back",
      "rightButton": _vm.rightButtonCaption,
      "step": 4,
      "rightDisabled": _vm.busy || _vm.disabledNextButton,
      "rightSpinner": _vm.busy && !_vm.imgs
    },
    on: {
      "left": function ($event) {
        return _vm.$router.push(`/${_vm.session.id}/calibration`);
      },
      "right": _vm.onNext
    }
  }, [_vm.imgs ? _c('v-card', {
    staticClass: "step-4-1 pa-2 d-flex flex-column"
  }, [_c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm._v(" Verify neutral pose ")]), _c('v-card-text', {
    staticClass: "d-flex flex-grow-1 scroll-y"
  }, _vm._l(_vm.imgCols, function (imgCol, colIndex) {
    return _c('div', {
      key: colIndex,
      staticClass: "d-flex flex-column"
    }, _vm._l(imgCol, function (img, imgIndex) {
      return _c('img', {
        key: imgIndex,
        staticClass: "ma-1",
        attrs: {
          "src": img,
          "width": "150"
        }
      });
    }), 0);
  }), 0)], 1) : _c('div', {
    staticClass: "step-4-1 d-flex flex-column"
  }, [_c('v-card', {
    staticClass: "mb-4"
  }, [_c('v-card-title', {
    staticClass: "justify-center subject-title"
  }, [_vm._v(" Session Info ")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "11"
    }
  }, [_c('v-autocomplete', {
    ref: "selectSubjectsRef",
    attrs: {
      "required": "",
      "item-text": "display_name",
      "item-value": "id",
      "label": "Subject",
      "items": _vm.loaded_subjects,
      "loading": _vm.subject_loading,
      "search-input": _vm.subject_search,
      "return-object": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.subject_search = $event;
      },
      "update:search-input": function ($event) {
        _vm.subject_search = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "append-item",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "intersect",
            rawName: "v-intersect",
            value: _vm.loadNextSubjectsListPage,
            expression: "loadNextSubjectsListPage"
          }]
        })];
      },
      proxy: true
    }, {
      key: "selection",
      fn: function () {
        return [_vm._v(_vm._s(_vm.subject.display_name))];
      },
      proxy: true
    }]),
    model: {
      value: _vm.subject,
      callback: function ($$v) {
        _vm.subject = $$v;
      },
      expression: "subject"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.openNewSubjectPopup
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1), _c('v-text-field', {
    attrs: {
      "label": "Session Name (optional)",
      "type": "text",
      "required": "",
      "error": _vm.formErrors.name != null,
      "error-messages": _vm.formErrors.name
    },
    model: {
      value: _vm.sessionName,
      callback: function ($$v) {
        _vm.sessionName = $$v;
      },
      expression: "sessionName"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "d-flex justify-center"
  }, [_c('v-card-title', {
    staticClass: "justify-center data-title"
  }, [_vm._v(" Data sharing agreement ")]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }])
  }, [_vm._v(" The information on this page as well as videos of your movement are uploaded to a secure cloud server for processing. Please have the subject select their data sharing preferences below. Identified videos do not have the face blurred, de-identified videos have faces blurred, and processed data (e.g., joint angles) is de-identified. Please read our privacy terms for details. ")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-center checkbox-wrapper"
  }, [_c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column checkbox-box",
    attrs: {
      "tag": "div"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "The subject understands that the recorded identified videos and processed data are stored in secure PHI-compliant storage and agrees to share them with the OpenCap development team for algorithm development. We may use this data in scientific publications, but will only publicly share the data that the subject agrees to share below.",
      "rules": [_vm.checkboxRule],
      "required": ""
    },
    on: {
      "click": _vm.isAllInputsValid
    },
    model: {
      value: _vm.data_sharing_0,
      callback: function ($$v) {
        _vm.data_sharing_0 = $$v;
      },
      expression: "data_sharing_0"
    }
  }), _c('ValidationProvider', {
    attrs: {
      "rules": "required",
      "name": "Data sharing agreement"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "label": "Please select which data the subject is willing to share publicly; we encourage the subject to share as much as they feel comfortable.",
            "items": _vm.data_sharing_choices,
            "error": errors.length > 0,
            "error-messages": errors[0]
          },
          on: {
            "change": _vm.isAllInputsValid
          },
          model: {
            value: _vm.data_sharing,
            callback: function ($$v) {
              _vm.data_sharing = $$v;
            },
            expression: "data_sharing"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-center"
  }, [[_c('div', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    staticClass: "mt-4 mb-4 ml-4 mr-4",
    attrs: {
      "color": "primary-dark",
      "x-large": ""
    },
    on: {
      "click": _vm.openAdvancedSettings
    }
  }, [_vm._v(" Advanced Settings ")]), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "width": "700px",
      "max-height": "400px"
    },
    model: {
      value: _vm.advancedSettingsDialog,
      callback: function ($$v) {
        _vm.advancedSettingsDialog = $$v;
      },
      expression: "advancedSettingsDialog"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "fit-content"
    }
  }, [_c('v-card-actions', {
    staticClass: "justify-end"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary-dark"
    },
    on: {
      "click": function ($event) {
        _vm.advancedSettingsDialog = false;
      }
    }
  }, [_vm._v("✖")])], 1), _c('v-card-title', {
    staticClass: "justify-center data-title"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Scaling setup")]), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }])
  }, [_vm._v(" OpenCap uses data from the neutral pose to scale the musculoskeletal model to the anthropometry of the subject. By default, OpenCap assumes that the subject is standing with an upright posture and the feet pointing forward (i.e., straight back and no bending or rotation at the hips, knees, or ankles) as shown in the example neutral pose. These assumptions are modeled in the OpenSim scaling setup. If the subject cannot adopt this pose, you can select the \"Any pose\" scaling setup, which does not assume any specific posture but still requires all body segments to be visible by at least two cameras. We recommend using the default scaling setup unless the subject cannot adopt the upright standing neutral pose. ")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-center checkbox-wrapper"
  }, [_c('v-select', {
    attrs: {
      "label": "Select scaling setup",
      "items": _vm.scaling_setups
    },
    model: {
      value: _vm.scaling_setup,
      callback: function ($$v) {
        _vm.scaling_setup = $$v;
      },
      expression: "scaling_setup"
    }
  })], 1), _c('v-card-title', {
    staticClass: "justify-center data-title"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Human pose estimation model")]), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }])
  }, [_vm._v(" OpenCap supports two human pose estimation models: OpenPose and HRNet. We recommend using OpenPose for computation speed, but both models provide similar accuracy. OpenPose is restricted to academic or non-profit organization non-commercial research use (consult the license at https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/master/LICENSE). HRNet, as implemented by Open-MMLab, has a permissive Apache 2.0 license (consult the license at https://github.com/open-mmlab/mmpose/blob/master/LICENSE). Please ensure that you have the rights to use the model you select. The OpenCap authors deny any responsibility regarding license infringement. ")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-center checkbox-wrapper"
  }, [_c('v-select', {
    attrs: {
      "label": "Select human pose estimation model",
      "items": _vm.pose_models
    },
    model: {
      value: _vm.pose_model,
      callback: function ($$v) {
        _vm.pose_model = $$v;
      },
      expression: "pose_model"
    }
  })], 1), _c('v-card-title', {
    staticClass: "justify-center data-title"
  }, [_vm._v(" Framerate ")]), _c('v-card-text', {
    staticClass: "d-flex flex-column align-center checkbox-wrapper"
  }, [_c('v-select', {
    attrs: {
      "label": "Select framerate",
      "items": _vm.framerates_available
    },
    on: {
      "change": _vm.updateFrequency
    },
    model: {
      value: _vm.framerate,
      callback: function ($$v) {
        _vm.framerate = $$v;
      },
      expression: "framerate"
    }
  })], 1), _c('v-card-title', {
    staticClass: "justify-center data-title"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Musculoskeletal model")]), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }])
  }, [_vm._v(" Full body model: Musculoskeletal model with 33 degrees of freedom from Lai et al. 2017 (https://pubmed.ncbi.nlm.nih.gov/28900782/) with modified hip abductor muscle paths from Uhlrich et al. 2022 (https://pubmed.ncbi.nlm.nih.gov/35798755/). Recommended for primarily lower extremity tasks (e.g., gait). "), _c('br'), _c('br'), _vm._v(" Full body model with ISB shoulder: Incorporates a 6 degree-of-freedom shoulder complex joint. It incorporates a scapulothoracic body with 3 translational degrees of freedom relative to the torso. The glenohumoral joint uses the Y-X-Y rotation sequence (elevation plane, elevation, rotation) recommended by the ISB (https://pubmed.ncbi.nlm.nih.gov/15844264/). Recommended for upper extremity tasks (e.g., pitching). ")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-center checkbox-wrapper"
  }, [_c('v-select', {
    attrs: {
      "label": "Select musculoskeletal model",
      "items": _vm.openSimModels
    },
    model: {
      value: _vm.openSimModel,
      callback: function ($$v) {
        _vm.openSimModel = $$v;
      },
      expression: "openSimModel"
    }
  })], 1), _c('v-card-title', {
    staticClass: "justify-center data-title"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Marker augmenter model")]), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }])
  }, [_vm._v(" OpenCap uses an LSTM model, also called marker augmenter model, to predict the 3D position of 43 anatomical markers from the 3D position of 20 video keypoints (https://www.biorxiv.org/content/10.1101/2022.07.07.499061v1). The anatomical markers are used as input to OpenSim to compute joint angles using inverse kinematics. "), _c('br'), _c('br'), _vm._v(" The latest model (v0.3, default) is more accurate and more robust to different activities than v0.2. We recommend using it for new studies. It was trained on 1475 hours of motion capture data and resulted in an RMSE of 4.4 +/- 0.3 deg (OpenPose) and 4.1 +/- 0.3 deg (HRnet) for joint angles across 18 degrees of freedom. "), _c('br'), _c('br'), _vm._v(" The original model (v0.2) underwent training using 708 hours of motion capture data, yielding an RMSE of 4.8 +/- 0.2 deg (OpenPose and HRNet) for joint angles across 18 degrees of freedom. "), _c('br'), _c('br'), _vm._v(" The performance evaluation was conducted in comparison to marker-based motion capture using data from 10 subjects performing 4 different types of activities (walking, squatting, sit-to-stand, and drop jumps). The dataset used for training the latest model (v0.3) contains data from more subjects and from a more diverse set of tasks; model v0.3 is therefore expected to be more accurate for a wider variety of tasks and to yield more accurate results. We recommend using v0.3 for new studies but warn users that we might still adjust the model in the future. If you would like to use the model that was default prior to 07-30-2023, select v0.2. ")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-center checkbox-wrapper"
  }, [_c('v-select', {
    attrs: {
      "label": "Select marker augmenter model",
      "items": _vm.augmenter_models
    },
    model: {
      value: _vm.augmenter_model,
      callback: function ($$v) {
        _vm.augmenter_model = $$v;
      },
      expression: "augmenter_model"
    }
  })], 1), _c('v-card-title', {
    staticClass: "justify-center data-title"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("Filter frequency")]), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on;
        return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
      }
    }])
  }, [_vm._v(" OpenCap uses a low-pass Butterworth filter to smooth the 2D video keypoints. The filter frequency is the cutoff frequency of the filter. "), _c('br'), _c('br'), _vm._v(" By default, OpenCap uses a filter frequency of half the framerate (if the framerate is 60fps, the filter frequency is 30Hz), except for gait activities, for which the filter frequency is 12Hz. "), _c('br'), _c('br'), _vm._v(" You can here enter a different filter frequency. WARNING: this filter frequency will be applied to ALL motion trials of your session. As per the Nyquist Theorem, the filter frequency should be less than half the framerate. If you enter a filter frequency higher than half the framerate, we will use half the framerate as the filter frequency instead. "), _c('br'), _c('br'), _vm._v(" We recommend consulting the literature to find a suitable filter frequency for your specific tasks. If you are unsure, we recommend using the default filter frequency. ")])], 1), _c('v-card-text', {
    staticClass: "d-flex flex-column align-center checkbox-wrapper"
  }, [_c('v-combobox', {
    key: _vm.componentKey,
    attrs: {
      "label": "Enter frequency (Hz) or choose default",
      "items": _vm.filter_frequencies,
      "allow-custom": true,
      "return-object": false,
      "item-text": "text",
      "item-value": "value"
    },
    on: {
      "change": _vm.validateAndSetFrequency
    },
    model: {
      value: _vm.tempFilterFrequency,
      callback: function ($$v) {
        _vm.tempFilterFrequency = $$v;
      },
      expression: "tempFilterFrequency"
    }
  })], 1)], 1)], 1)], 1)]], 2)], 1), _c('v-card', {
    staticClass: "step-4-2 ml-4 d-flex images-box"
  }, [_c('v-card', {
    staticClass: "mb-0"
  }, [_c('v-card-text', {
    staticStyle: {
      "padding-top": "5px",
      "padding-bottom": "0",
      "font-size": "16px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.n_videos_uploaded) + " of " + _vm._s(_vm.n_calibrated_cameras) + " videos uploaded")])])], 1), _c('v-card-title', {
    staticClass: "justify-center"
  }, [_vm._v(" Record neutral pose ")]), _c('v-card-text', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column mr-4"
  }, [_c('ul', [_c('li', [_vm._v(" The subject should adopt the example neutral pose "), _c('ul', [_c('li', {
    staticClass: "space-above-small"
  }, [_vm._v("Upright standing posture with feet pointing forward")]), _c('li', {
    staticClass: "space-above-small"
  }, [_vm._v("Straight back and no bending or rotation at the hips, knees, or ankles")])])]), _c('li', {
    staticClass: "space-above-small"
  }, [_vm._v("The subject should stand still")]), _c('li', {
    staticClass: "space-above-small"
  }, [_vm._v(" The subject should be visible by all cameras "), _c('ul', [_c('li', {
    staticClass: "space-above-small"
  }, [_vm._v("Nothing in the way of cameras view when hitting Record")])])])])]), _c('div', {
    staticClass: "d-flex flex-column align-center"
  }, [_c('span', {
    staticClass: "sub-header",
    staticStyle: {
      "font-size": "18px"
    }
  }, [_vm._v("Example neutral pose")]), _c('ExampleImage', {
    attrs: {
      "image": "/images/step-4/big_good_triangle.jpg",
      "width": 256,
      "height": 341,
      "good": ""
    }
  })], 1)]), _c('v-card-title', {
    staticClass: "justify-center",
    staticStyle: {
      "font-size": "18px",
      "word-break": "keep-all"
    }
  }, [_vm._v(" If the subject cannot adopt the example neutral pose, select \"Any pose\" scaling setup under Advanced Settings ")])], 1), _c('DialogComponent', {
    ref: "dialogRef",
    on: {
      "subject-added": _vm.submitAddSubject
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }