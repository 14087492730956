var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "pa-2 d-flex"
  }, [_c('div', {
    staticClass: "container"
  }, [_vm.userExist ? _c('div', [!_vm.editing_profile && !_vm.editing_settings ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center pb-4"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-img', {
    staticClass: "rounded-circle img-fluid mt-4 mb-8",
    attrs: {
      "max-width": "50%",
      "src": _vm.current_user_page_profile_url,
      "alt": "Profile Picture"
    }
  }), _vm.editable ? _c('v-btn', {
    on: {
      "click": _vm.handleChangeImage
    }
  }, [_vm._v("Change image")]) : _vm._e(), _vm.editable ? _c('br') : _vm._e(), _c('button', {
    on: {
      "click": _vm.handleShareProfileClick
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center pb-0",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.username_param) + " "), _c('i', {
    staticClass: "mdi mdi-share ml-1 me-1 vertical-middle"
  })])], 1), _c('v-card-text', {
    staticClass: "pa-1"
  }, [_vm._v(" " + _vm._s(_vm.institution) + " ")]), _c('v-card-text', {
    staticClass: "pa-1"
  }, [_c('a', {
    staticClass: "link-primary mb-1",
    attrs: {
      "href": 'mailto:' + _vm.email,
      "target": "_blank",
      "rel": "noreferrer"
    }
  }, [_c('p', {
    staticClass: "mb-1"
  }, [_c('i', {
    staticClass: "mdi mdi-email-box me-1 vertical-middle"
  }), _vm._v(" Contact ")])])]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.editable ? _c('v-btn', {
    staticClass: "my-4",
    on: {
      "click": _vm.handleEditProfile
    }
  }, [_vm._v(" Edit Profile ")]) : _vm._e()], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.editable ? _c('v-btn', {
    staticClass: "mb-2",
    on: {
      "click": _vm.handleEditSettings
    }
  }, [_vm._v(" Settings ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "col-lg-8"
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center"
  }, [_c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "col-lg-4"
  }, [_c('strong', [_c('i', {
    staticClass: "mdi mdi-account me-1 vertical-middle"
  }), _vm._v(" Full Name ")])]), _c('v-col', {
    staticClass: "col-lg-8"
  }, [_vm._v(_vm._s(_vm.first_name) + " " + _vm._s(_vm.last_name) + " ")])], 1), _vm.profession ? _c('v-row', [_c('v-col', {
    staticClass: "col-lg-4"
  }, [_c('strong', [_c('i', {
    staticClass: "mdi mdi-briefcase me-1 vertical-middle"
  }), _vm._v(" Profession ")])]), _c('v-col', {
    staticClass: "col-lg-8"
  }, [_vm._v(_vm._s(_vm.profession) + " ")])], 1) : _vm._e(), _vm.website ? _c('v-row', [_c('v-col', {
    staticClass: "col-lg-4"
  }, [_c('strong', [_c('i', {
    staticClass: "mdi mdi-web me-1 vertical-middle"
  }), _vm._v(" Personal Website ")])]), _c('v-col', {
    staticClass: "col-lg-8"
  }, [_c('a', {
    attrs: {
      "href": _vm.website,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.website) + " ")])])], 1) : _vm._e(), _vm.reason_of_use ? _c('v-row', [_c('v-col', {
    staticClass: "col-lg-4"
  }, [_c('strong', [_c('i', {
    staticClass: "mdi mdi-information me-1 vertical-middle"
  }), _vm._v(" Reason of use ")])]), _c('v-col', {
    staticClass: "col-lg-8"
  }, [_vm._v(_vm._s(_vm.reason_of_use) + " ")])], 1) : _vm._e(), _vm.country ? _c('v-row', [_c('v-col', {
    staticClass: "col-lg-4"
  }, [_c('strong', [_c('i', {
    staticClass: "mdi mdi-flag me-1 vertical-middle"
  }), _vm._v(" Country ")])]), _c('v-col', {
    staticClass: "col-lg-8"
  }, [_vm._v(_vm._s(_vm.country) + " ")])], 1) : _vm._e()], 1)], 1)], 1)]) : _vm.editing_profile ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('h1', {
    staticClass: "white--text text-center col-md-12"
  }, [_vm._v("Editing Profile")]), _c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Edit"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Username",
                  "dark": "",
                  "readonly": true,
                  "disabled": true,
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.username,
                  callback: function ($$v) {
                    _vm.username = $$v;
                  },
                  expression: "username"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "First name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref3) {
              var errors = _ref3.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "First name",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.first_name,
                  callback: function ($$v) {
                    _vm.first_name = $$v;
                  },
                  expression: "first_name"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Last name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var errors = _ref4.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Last name",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.last_name,
                  callback: function ($$v) {
                    _vm.last_name = $$v;
                  },
                  expression: "last_name"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6 d-flex align-items-center"
        }, [_c('div', {
          staticClass: "form-outline datepicker w-100"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required|email",
            "name": "Email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref5) {
              var errors = _ref5.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Email (will be used for two-factor authentication)",
                  "dark": "",
                  "readonly": false,
                  "disabled": false,
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v;
                  },
                  expression: "email"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('vue-country-dropdown', {
          ref: "vcd",
          attrs: {
            "preferredCountries": ['US'],
            "defaultCountry": _vm.countryCode,
            "immediateCallSelectEvent": true,
            "enabledFlags": true,
            "enabledCountryCode": false,
            "showNameInput": true
          },
          on: {
            "onSelect": _vm.onSelectCountry
          }
        })], 1)]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6 pb-2"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Institution"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref6) {
              var errors = _ref6.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Institution",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.institution,
                  callback: function ($$v) {
                    _vm.institution = $$v;
                  },
                  expression: "institution"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "col-md-6 pb-2"
        }, [_c('div', {
          staticClass: "form-outline"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Profession"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref7) {
              var errors = _ref7.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Profession",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.profession,
                  callback: function ($$v) {
                    _vm.profession = $$v;
                  },
                  expression: "profession"
                }
              })];
            }
          }], null, true)
        })], 1)])]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('ValidationProvider', {
          attrs: {
            "rules": "required",
            "name": "Reason"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref8) {
              var errors = _ref8.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Reason for use",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.reason_of_use,
                  callback: function ($$v) {
                    _vm.reason_of_use = $$v;
                  },
                  expression: "reason_of_use"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Website"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref9) {
              var errors = _ref9.errors;
              return [_c('v-text-field', {
                staticClass: "ma-0",
                attrs: {
                  "label": "Website",
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.website,
                  callback: function ($$v) {
                    _vm.website = $$v;
                  },
                  expression: "website"
                }
              })];
            }
          }], null, true)
        })], 1)]), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-12"
        }, [_c('v-checkbox', {
          attrs: {
            "label": "Sign up to receive our newsletter"
          },
          model: {
            value: _vm.newsletter,
            callback: function ($$v) {
              _vm.newsletter = $$v;
            },
            expression: "newsletter"
          }
        })], 1)]), _c('div', {
          staticClass: "pt-2"
        }, [_c('v-btn', {
          staticClass: "white--text mx-0 align-self-center mb-6",
          attrs: {
            "type": "submit",
            "disabled": _vm.submitted && invalid || _vm.loading
          },
          on: {
            "click": function ($event) {
              return _vm.onChangeProfile();
            }
          }
        }, [_vm._v(" Save Changes ")])], 1)];
      }
    }], null, false, 1781315718)
  }), _c('router-link', {
    staticClass: "text-center mt-6",
    attrs: {
      "to": {
        name: 'ProfilePage'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleDiscard.apply(null, arguments);
      }
    }
  }, [_vm._v(" Discard Changes ")])], 1)]) : _vm.editing_settings ? _c('div', {
    staticClass: "row"
  }, [_c('v-col', {
    staticClass: "mt-8",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('p', [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.isAuditoryFeedbackEnabled,
      expression: "isAuditoryFeedbackEnabled"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.isAuditoryFeedbackEnabled) ? _vm._i(_vm.isAuditoryFeedbackEnabled, null) > -1 : _vm.isAuditoryFeedbackEnabled
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.isAuditoryFeedbackEnabled,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.isAuditoryFeedbackEnabled = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.isAuditoryFeedbackEnabled = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.isAuditoryFeedbackEnabled = $$c;
        }
      }, _vm.updateLocalStorage]
    }
  }), _vm._v(" Enable Voice Auditory Feedback (audio updates for start and completion events). ")])])]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('p', [_vm._v(" Remove your account and all associated data. This includes every session, trial, subject, and result that you have ever created. This process is irreversible. ")])]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.editable ? _c('v-btn', {
    staticClass: "mb-2 red--text",
    on: {
      "click": _vm.handleOpenDeleteAccount
    }
  }, [_vm._v(" Delete Account ")]) : _vm._e()], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('router-link', {
    staticClass: "text-center mt-6",
    attrs: {
      "to": {
        name: 'ProfilePage'
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleDiscard.apply(null, arguments);
      }
    }
  }, [_vm._v(" Discard Changes ")])], 1)], 1)], 1) : _vm._e(), _vm.deletingAccount ? _c('div', {
    staticClass: "popup",
    on: {
      "click": function () {
        _vm.deletingAccount = false;
      }
    }
  }, [_c('div', {
    staticClass: "popup-content",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('h2', {
    staticStyle: {
      "color": "#f44336!important"
    }
  }, [_vm._v("Delete Account")]), _c('br'), _c('span', {
    staticStyle: {
      "color": "#f44336!important"
    }
  }, [_vm._v("WARNING!")]), _vm._v(" This action will permanently remove your account and all associated data. "), _c('br'), _vm._v(" This includes every session, trial, subject, and result you have ever created. "), _c('br'), _vm._v(" This process is irreversible. "), _c('br'), _c('br'), _vm._v(" If you wish to proceed, please type in your username and click on \"Delete Account\". "), _c('br'), _c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-outline"
  }, [_c('ValidationProvider', {
    attrs: {
      "rules": "required",
      "name": "delete-user-confirm"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var errors = _ref10.errors;
        return [_c('v-text-field', {
          staticClass: "ma-0",
          attrs: {
            "label": "Type in your username to confirm the deletion of your account",
            "dark": "",
            "error": errors.length > 0,
            "error-messages": errors[0]
          },
          model: {
            value: _vm.confirm_username,
            callback: function ($$v) {
              _vm.confirm_username = $$v;
            },
            expression: "confirm_username"
          }
        })];
      }
    }], null, false, 1639175831)
  }), _c('br'), _vm.editable ? _c('v-btn', {
    staticClass: "mb-2 red--text",
    on: {
      "click": _vm.handleDeleteAccount
    }
  }, [_vm._v(" Delete Account ")]) : _vm._e()], 1)]), _c('br'), _c('router-link', {
    staticClass: "text-center mt-6",
    attrs: {
      "to": {
        name: 'ProfilePage',
        params: {
          username: this.username
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleDiscardDeleteAccount.apply(null, arguments);
      }
    }
  }, [_vm._v(" Do not remove my account ")])], 1)]) : _vm._e(), _vm.changingImage ? _c('div', {
    staticClass: "popup",
    on: {
      "click": function () {
        _vm.changingImage = false;
      }
    }
  }, [_c('div', {
    staticClass: "popup-content",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('h2', [_vm._v("Upload Image")]), _vm.selectedImage ? _c('img', {
    staticClass: "profile-image-preview rounded-circle img-fluid mt-4",
    attrs: {
      "src": _vm.selectedImage,
      "alt": "Uploaded Image"
    }
  }) : _vm._e(), _c('br'), _c('input', {
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "change": _vm.handleImageUploaded
    }
  }), _c('br'), _c('v-btn', {
    staticClass: "my-4",
    on: {
      "click": function ($event) {
        return _vm.handleSaveImage();
      }
    }
  }, [_vm._v("Save Image")]), _c('br'), _c('router-link', {
    staticClass: "text-center mt-6",
    attrs: {
      "to": {
        name: 'ProfilePage',
        params: {
          username: this.username
        }
      }
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.handleDiscard.apply(null, arguments);
      }
    }
  }, [_vm._v(" Discard Changes ")])], 1)]) : _vm._e()]) : _c('div', [_vm._v(" The user \"" + _vm._s(_vm.username_param) + "\" does not exist. ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }